import { createTheme } from '@mui/material';

const ACCENT_COLOR = '#34a0ff';

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          height: '100%',
        },

        body: {
          height: '100%',
        },

        'ul, p, ol': {
          margin: 0,
          padding: 0,
        },

        a: {
          textDecoration: 'none',
        },
      },
    },
  },
  typography: {
    fontFamily: [
      'Manrope',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'sans-serif',
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
    ].join(','),

    h1: {
      fontWeight: 700,
      fontSize: 23,
      color: '#004679',
      textTransform: 'uppercase',

      '@media (min-width: 768px)': {
        fontSize: 30,
        lineHeight: 1.2,
        letterSpacing: 1,
      },

      '@media (min-width: 1380px)': {
        fontSize: 36,
        lineHeight: 1.4,
        letterSpacing: 1.6,
      },
    },

    h2: {
      fontSize: 20,
      fontWeight: 700,
      lineHeight: 1.7,
      color: '#004679',
      textTransform: 'uppercase',
      letterSpacing: 1,

      '@media (min-width: 1030px)': {
        fontSize: 30,
      },
    },

    h2Secondary: {
      display: 'block',
      fontSize: 30,
      fontWeight: 700,
      lineHeight: 1,
      color: '#004679',
      textTransform: 'uppercase',
      letterSpacing: '1.3px',
    },

    h3: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: 1,
      textTransform: 'uppercase',
      letterSpacing: '0.9px',
    },

    heroSubtitle: {
      fontSize: 18,
      fontWeight: 700,
      letterSpacing: 0.7,

      '@media (min-width: 1030px)': {
        lineHeight: 1.3,
      },

      '@media (min-width: 1380px)': {
        lineHeight: 2.3,
      },
    },

    newsTitle: {
      fontSize: 25,
      fontWeight: 700,
      lineHeight: 1,
      color: '#004679',
      textTransform: 'uppercase',
      letterSpacing: '1.3px',
    },

    text: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: 1.4,
      letterSpacing: 0.7,
    },

    logoText: {
      fontSize: 9,
      letterSpacing: 0.1,
      color: '#ffffff',
      textAlign: 'center',
    },

    body1: {
      '@media (max-width: 1030px)': {
        lineHeight: '20px',
        letterSpacing: '0px',
      },
    },

    footerText: {
      fontSize: 14,
      lineHeight: 1.2,
      // fontWeight: 600,
      letterSpacing: 0.1,
      color: '#ffffff',
    },

    formText: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1,
    },

    cabinetText: {
      fontSize: 16,
      lineHeight: '24px',
      letterSpacing: 0.5,
    },
  },

  colors: {
    darkBlue: '#004679',
    accentBlue: ACCENT_COLOR,
    accentBlueTransparent: 'rgba(52, 160, 255, 0.1)',
    lightBlue: '#e7f7ff',
    red: '#d32f2f',
    green: 'green',
    grey: '#5b5b5b',
    lightGrey: '#e7e5e5',
    greyLink: '#3e4854',
    white: '#ffffff',
    black: '#000000',
    blackTitle: '#000203',
  },

  options: {
    tabsBorder: ACCENT_COLOR,
  },

  breakpoints: {
    values: {
      mobile: 320,
      mobileMid: 420,
      tabletMin: 768,
      tabletMid: 900,
      tablet: 1030,
      desktop: 1380,
    },
  },
});

export default theme;
